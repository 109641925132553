@font-face { font-family: "Lato-Regular"; src: url("fonts/Lato-Regular.eot"); src: url("fonts/Lato-Regular.woff") format("woff"), url("fonts/Lato-Regular.ttf") format("truetype"), url("fonts/Lato-Regular.svg") format("svg"); font-weight: normal; font-style: normal; }
@font-face { font-family: "Lato-Light"; src: url("fonts/Lato-Light.eot"); src: url("fonts/Lato-Light.woff") format("woff"), url("fonts/Lato-Light.ttf") format("truetype"), url("fonts/Lato-Light.svg") format("svg"); font-weight: normal; font-style: normal; }
@font-face { font-family: "Rogan-Bold"; src: url("fonts/324775_0_0.eot"); src: url("fonts/324775_0_0.eot?#iefix") format("embedded-opentype"),url("fonts/324775_0_0.woff2") format("woff2"),url("fonts/324775_0_0.woff") format("woff"),url("fonts/324775_0_0.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: "Rogan-Light"; src: url("fonts/324775_1_0.eot"); src: url("fonts/324775_1_0.eot?#iefix") format("embedded-opentype"),url("fonts/324775_1_0.woff2") format("woff2"),url("fonts/324775_1_0.woff") format("woff"),url("fonts/324775_1_0.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: "Rogan-Regular"; src: url("fonts/324775_A_0.eot"); src: url("fonts/324775_A_0.eot?#iefix") format("embedded-opentype"),url("fonts/324775_A_0.woff2") format("woff2"),url("fonts/324775_A_0.woff") format("woff"),url("fonts/324775_A_0.ttf") format("truetype"); font-weight: normal; font-style: normal; }
@font-face { font-family: "Rogan-Medium"; src: url("fonts/324775_B_0.eot"); src: url("fonts/324775_B_0.eot?#iefix") format("embedded-opentype"),url("fonts/324775_B_0.woff2") format("woff2"),url("fonts/324775_B_0.woff") format("woff"),url("fonts/324775_B_0.ttf") format("truetype"); font-weight: normal; font-style: normal; }

* { margin: 0; box-sizing: border-box; }
body { font-family: "Lato-Regular", Arial, sans-serif; background: #0057b8; color: #fff; font-size: 14px; max-height: 100%; }
a { color: #00aeef; text-decoration: none; cursor: pointer; white-space: nowrap; }
a:hover { color: #6fd6fd; text-decoration: underline; outline: 0; }

.video-container { filter: blur(0px); display: none; position: absolute; width: 100%; z-index: -1; overflow: hidden; max-height: calc(100% - 10px); }
.video-container .overlay { background-color: rgba(0, 87, 184, 0.8); height: 100%; width: 100%; z-index: 1; display: block; position: fixed; }
.video-container video { width: 100%; height: auto; max-height: 100%; }

header { background: #fff; height: 70px; box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1); }
header .logo-container { width: 97.5%; margin: 0 auto; max-width: 1180px; padding-left: 15px; }
header .logo-container a { margin-top: 15px; float: left; display: block; height: 39px; }
header .logo-container a.custom-logo { margin-top: 9px; float: left; display: block; height: 49px; }
header .logo-container a img { height: 39px; border: none; }
header .logo-container a.custom-logo img { height: 49px; border: none; }
header .logo-container h2 { float: left; font-weight: normal; margin-top: 26px; color: #666; font-size: 16px; font-family: "Rogan-Regular", sans-serif; text-transform: uppercase; margin-left: 15px; }
header .logo-container h2 span { border-left: 1px solid #999; margin-left: 10px; padding-left: 10px; letter-spacing: 0.8px; }

.main { width: 97.5%; margin: 0 auto; max-width: 1180px; padding-top: 60px; padding-bottom: 40px; }
.main::after { content: ""; display: table; clear: both; }
.main h2 { font-family: "Rogan-Medium", sans-serif; background: #333; color: #fff; font-size: 18px; font-weight: normal; margin: 0; padding: 10px; text-align: center; }
.main h3 { font-family: "Rogan-Medium", sans-serif; font-size: 1.5em; margin-bottom: 18px; }
.main p { margin-bottom: 20px; line-height: 1.5; }
.main label { display: inline-block; margin-bottom: 10px; }
.main input[type="text"],
.main input[type="password"],
.main input[type="email"] { line-height: 20px; color: #555; background: #fff; box-shadow: inset 0 1px 1px rgba(0,0,0,.075); border: none; border-radius: 0; padding: 10px; font-size: 16px; height: initial; width: 100%; font-family: "Lato-Regular", Arial, sans-serif; margin-bottom: 18px; }
.main .btn { font-family: "Lato-Regular", Arial, sans-serif; text-decoration: none; line-height: 1.7; width: 100%; background: #9dc44a; color: #fff; font-size: 16px; height: initial; border: 0; padding: 7px; margin: 5px 0; -webkit-appearance: none; border-radius: 0; }
.main .btn:not([disabled]) { cursor: pointer; }
.main .btn:not([disabled]):hover { background: #b4e156; text-decoration: none; }
.main .btn[disabled] { opacity: 0.5; }
.main .btn-lg { padding: 10px 16px; }
.main .page-container { padding: 40px; background: rgba(255, 255, 255, 0.15); box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05); }

.main .input-section { float: left; width: 33.33333333%; min-height: 1px; padding-right: 15px; padding-left: 15px; }
.main .input-section a { color: white; }
.main .input-section .note { font-size: 14px; color: #fdb813; background-color: rgba(0,0,0,.3); padding: 7px 10px; }
.main .input-section .page { display: none; }
.main .input-section .page.active { display: block; }
.main .input-section .actions-line { margin-top: 20px; }
.main .input-section .result-message > *:not(:empty) { display: block; margin-bottom: 20px; color: #fdb813; background-color: rgba(0,0,0,.3); padding: 11px 22px; font-size: 15px; }
.ie8 .main .input-section .login-form,
.ie8 .main .input-section .actions-line { display: none; }

.main .info-section { width: 66.66666667%; float: left; min-height: 1px; padding-right: 15px; padding-left: 15px; }
.main .info-section h1 { font-family: "Rogan-Light", sans-serif; font-size: 70px; margin-top: 0; text-transform: uppercase; line-height: 65px; color: #fff; text-shadow: none; font-weight: normal; margin-bottom: 55px; }
.main .info-section p { margin-bottom: 20px; line-height: 1.5; font-size: 16px; }

.main .error-section { width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; }
.main .error-section .page-container { min-height: 400px; background: rgba(255, 255, 255, 0.25); }
.main .error-section h1 { font-family: "Rogan-Light", sans-serif; font-size: 60px; line-height: 55px; margin-top: 0; text-transform: uppercase; margin-bottom: 20px; font-weight: normal; }
.main .error-section p { margin-bottom: 15px; font-size: 16px; }
.main .error-section .btn { margin-top: 20px; width: auto; display: inline-block; text-decoration: none; }

.content { min-height: calc(100vh - 139px); padding-top: 10px; }
.error-page .content { background: #0057b8 url("images/Doctors.jpg"); background-repeat: no-repeat; background-size: 100% auto; background-position: center 80px; }
@media (max-width: 1295px) {
    .error-page .content { background-size: auto; }
}

footer { height: 139px; background: #333; padding: 30px 34px; }
footer .menu { padding: 0; margin: 0; list-style: none; text-align: center; margin-bottom: 4px; }
footer .menu li { display: inline-block; padding: 0 8px; }
footer .menu li a { display: block; padding: 10px 15px; }
footer .credits { text-align: center; padding-top: 5px; }
footer .credits span { white-space: nowrap; }

.pull-right { float: right; }
iframe { visibility: collapse; width: 1px; height: 1px; position: absolute; bottom: 0; }

@media (max-width: 1200px) {
    header .logo-container { max-width: 600px; }
    .main { max-width: 600px; }
    .main .input-section,
    .main .info-section { float: none; width: 100%; margin-bottom: 30px; }
    .main .info-section h1 { margin-bottom: 30px; font-size: 50px; }
    .video-container,
    .js-is-video-visible { display: none !important; }
}

@media (max-width: 550px) {
    header .logo-container h2 span { display: none; }
    header .logo-container a { margin-left: 15px; }
}

@media (max-width: 390px) {
    footer .menu li a { display: block; padding: 10px 5px; }
}

@media (max-width: 330px) {
    .main .input-section a { float: left; clear: both; margin-bottom: 5px; }
    .main .input-section .actions-line { content: ""; display: table; clear: both; }
    .main .info-section h1 { font-size: 40px; }
}
